import React from 'react'

const Content = ({ /* title, */ description }) => (
  <div className="row">
    <div className="col-12">
      {/* <h2>{title}</h2> */}

      <div
        className="mt-40"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  </div>
)

export default Content
