import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import makeSlug from '@u/make-slug'

import Content from '@v/contracts/content'
import DatoCmsEmbeddedModel from '../models/DatoCmsEmbeddedModel'

const Page = ({ data, pageContext }) => {
  const { page } = data
  const { pageType } = pageContext
  const isUserAgreementPage = pageType === 'userAgreement'
  const title = isUserAgreementPage
    ? data.page.userAgreementTitle
    : data.page.privacyPolicyTitle
  const parentPages = [
    { url: makeSlug.generic(page.locale, page.slug), title: page.title }
  ]
  const { _allUserAgreementSlugLocales, _allPrivacyPolicySlugLocales } = page
  const model = new DatoCmsEmbeddedModel(
    { title },
    {
      slugLocales: isUserAgreementPage
        ? _allUserAgreementSlugLocales
        : _allPrivacyPolicySlugLocales,
      locale: page.locale
    }
  )

  return (
    <Layout
      locale={page.locale}
      seo={{ ...model.getSeoComponentProps(page._allSlugLocales) }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb
              currentPage={title}
              parentPages={parentPages}
              wrapCurrentPageWithH1={true}
            />
          </div>
        </div>
      </div>

      {/* PAGE */}
      <div className="container">
        <div className="row">
          {/* SIDEBAR */}
          <div className="col-12 col-lg-2 d-none d-lg-block">
            {/* <div className="u-sticky">heyyyo</div> */}
          </div>

          {/* CONTENT */}
          <div className="col-12 col-lg-8">
            <Content
              description={
                pageType === 'userAgreement'
                  ? data.page.userAgreement
                  : data.page.privacyPolicy
              }
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ContractDetailQuery($locale: String) {
    page: datoCmsContractPage(locale: { eq: $locale }) {
      _allSlugLocales {
        value
        locale
      }
      _allUserAgreementSlugLocales {
        value
        locale
      }
      _allPrivacyPolicySlugLocales {
        value
        locale
      }
      locale
      title
      slug

      userAgreementTitle
      userAgreementSlug
      userAgreement

      privacyPolicyTitle
      privacyPolicySlug
      privacyPolicy
    }
  }
`
